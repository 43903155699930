const nicknames = [
    {
        name: "Trash Panda",
        bottom: 0,
        top: 30
    },
    {
        name: "Clean Team",
        bottom: 31,
        top: 60
    },
    {
        name: "Big Green",
        bottom: 61,
        top: 90
    },
    {
        name: "Eco Warrior",
        bottom: 91,
        top: 120
    },
    {
        name: "Recycling Lord",
        bottom: 121,
        top: 150
    },
]

export default nicknames;